import styled from "styled-components";
import { StyledInput } from "@pages/myPricingTools/buildingTools/priceModels/priceCommunication/components/priceCommunicationConfigurationPanel/PriceCommunicationConfigurationPanel.style";
import { Icon, Radio } from "priceit-ui";
import { customGap } from "@services/Utils.style";

export const EmptyWrapper = styled.div`
	flex: 1;
`;
export const WrapperTitle = styled.div`
	color: #ffcd38;
	margin: 0 0 10px 0;
	display: flex;
	align-items: center;
	text-transform: uppercase;
`;

export const SubSectionTitle = styled.div`
	font-size: 12px;
	color: #a5b9c8;
`;

export const WrapperInfoIcon = styled.div`
	padding: ${props => props.padding ?? 0};
	display: flex;
	align-items: center;
`;

export const InfoIcon = styled(Icon)`
	fill: ${props => (props.checked ? "#FFFFFF" : "#A5B9C8")};
	width: 12px;
	height: ${props => props.height ?? "12px"};
	margin: ${props => props.margin ?? 0};
`;

export const WrapperRadio = styled.div``;

export const WrapperInput = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
export const WrapperInputLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const WrapperInputLabelText = styled.div``;

export const TCButtonInput = styled(StyledInput)``;

export const TCURLOrLinkInput = styled(StyledInput)``;

export const WrapperTcLink = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperTcLinkInput = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
	${customGap(5)}
`;

export const WrapperTcPDF = styled.div`
	display: flex;
	flex-direction: column;
`;

export const WrapperUploadPDF = styled.div`
	display: flex;
	align-items: center;
	${customGap(5)}
	padding: 10px 0;
`;

export const StyledIcon = styled(Icon)`
	cursor: pointer;
	fill: ${({ theme }) => theme.page.secondaryTextColor};
	&:hover {
		fill: ${({ theme }) => theme.page.textColor};
	}
	width: 14px;
	height: 14px;
`;

export const DeleteIcon = styled(StyledIcon)``;

export const UrlDeleteIcon = styled(StyledIcon)`
	visibility: hidden;
	${WrapperTcLinkInput}:hover & {
		visibility: visible;
	}
`;

export const RadioButton = styled(Radio.Button)`
	align-items: baseline;

	& > :last-child {
		overflow: hidden;
		flex: 1;
		padding-right: 0;
	}
`;

export const PDFName = styled.a`
	text-decoration: none;
	color: #fff;
	flex: 1;
	overflow: hidden;

	&:hover,
	&:visited,
	&:link,
	&:active {
		text-decoration: none;
	}
`;

export const WrapperContent = styled.div`
	width: 1120px;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	flex: 1;
`;

export const WrapperFlex = styled.div`
	display: flex;
	gap: 20px;
	flex-direction: column;
	padding: 30px 0 30px 55px;
	overflow-y: auto;
`;
export const WrapperDescription = styled.div`
	color: white;
	font-size: 16px;
`;

export const WrapperTCCards = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
`;

export const TCCard = styled.div`
	padding: 25px;
	width: 500px;
	display: flex;
	gap: 15px;
	flex-direction: column;
	box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
	border-radius: 10px;
	background-color: #404d57;
	&:hover {
		background-color: #495965;
	}
`;

export const WrapperFlexBetween = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const WrapperFlexRow = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
