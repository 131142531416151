import React, { useEffect } from "react";
import { Button, Dialog } from "priceit-ui";
import { useTranslation } from "react-i18next";
import { createErrorTaskMutation } from "./graphQl/mutation";
import { useLocation } from "react-router-dom";
import { useThemeContext } from "@theme/Theme";
import openReplayTracker from "../../../config/openReplay";

export const ErrorBoundary = ({ componentStack, error, resetError }) => {
	const { Modal } = Dialog;
	const { mode, errorBoundaryModal: errorBoundaryModalColors } = useThemeContext();

	const location = useLocation();
	const { t, i18n } = useTranslation("welcomeMessage/page");
	const createErrorTask = createErrorTaskMutation();
	const customHeader = (image, width, height) => (
		<div
			style={{
				display: "flex",
				color: "white",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<img
				style={{
					height,
					width,
					overflow: "hidden",
					margin: "50px 0 30px 0",
				}}
				alt={image}
				src={image}
			/>
		</div>
	);
	if (error?.message && /Loading chunk [\d]+ failed/.test(error.message))
		return (
			<Modal
				positionFromTop={"50px"}
				width="410px"
				footerType="1button"
				visible={true}
				theme={{
					backgroundColor: errorBoundaryModalColors.backgroundColor,
					dividerColor: errorBoundaryModalColors.dividerColor,
				}}
				onOk={() => window.location.reload()}
				okText={t("Refresh")}
				noContentPadding
				closable={false}
				customHeader={customHeader(
					`https://${process.env.IMAGE_PROVIDER}/images/miscellaneous/${
						i18n.languages[0] ?? "en"
					}/Refresh.svg`,
					"256px",
					"auto"
				)}
				onCancel={() => {}}
			/>
		);

	useEffect(() => {
		openReplayTracker.handleError(error);
	}, []);

	const sessionURL = openReplayTracker.getSessionURL({ withCurrentTime: true });

	return (
		<Modal
			positionFromTop={"50px"}
			width="640px"
			visible={true}
			theme={{
				backgroundColor: errorBoundaryModalColors.backgroundColor,
				dividerColor: errorBoundaryModalColors.dividerColor,
			}}
			onOk={() => null}
			onCancel={() => null}
			noContentPadding
			customHeader={customHeader(
				`https://${process.env.IMAGE_PROVIDER}/images/${
					i18n.languages[0] ?? "en"
				}/AnErrorHasOccured_NewBis.svg`,
				"auto",
				"100%"
			)}
			customFooter={[
				<div key={"buttonsDiv"} style={{ display: "flex" }}>
					<Button
						key="refresh"
						mode={mode}
						onClick={() => {
							window.location.reload();
						}}
						type="cancel"
						width="200px"
						style={{ margin: "0 10px" }}
					>
						{t("Refresh")}
					</Button>
					<Button
						key="report"
						mode={mode}
						onClick={async () => {
							await createErrorTask({
								variables: {
									error: error?.toString(),
									componentStack,
									location: location.pathname,
									sessionURL,
								},
							});
							resetError();
							window.location.reload();
						}}
						type="secondary"
						style={{ margin: "0 10px", minWidth: "200px" }}
					>
						{t("Refresh and send a report")}
					</Button>
				</div>,
			]}
		/>
	);
};
