import React, { memo } from "react";
import * as S from "./TotalBoxes.style";
import { updateUpdateAtInCacheForLinks } from "@pages/myPricingTools/buildingTools/priceModels/overview/links/drawer/drawerLinksService";
import { Checkbox, SingleSelect, Tooltipv3 } from "priceit-ui";
import { StyledTextCheckbox } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/CombinationModal.style";
import {
	updateQuoteConfigurationLayoutMutation,
	updateQuoteConfigurationShowFirstPaymentBoxMutation,
	updateQuoteConfigurationShowTotalBoxesMutation,
} from "@graphQl/mutations/quoteConfiguration";
import { useQuoteConfigurationData } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/hooks/useQuoteConfigurationData";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "@theme/Theme";
import { RowWrapper } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/generalSettings/components/brandingAndDesign/BrandingAndDesign.style";
import { StyledCheckboxGroup } from "@pages/myPricingTools/buildingTools/priceModels/components/combinationModal/components/optionalSettings/components/quoteGenerator/components/quoteHeader/components/QuoteLayout.style";
import {
	PacksAndSectionsOptionContent,
	TooltipContent,
	WarningIcon,
	WrapperSelectAndIcon,
	WrapperWarningIcon,
} from "./TotalBoxes.style";

const TotalBoxes = memo(({ offerId, priceModelCombinationId, isOfferParameters }) => {
	const { t } = useTranslation("specific/priceModel");
	const { mode, feedback: feedbackColors } = useThemeContext();

	const { quoteConfigurationId, showTotalBoxes, layout, showFirstPaymentBox } =
		useQuoteConfigurationData({
			offerId,
			priceModelCombinationId,
		});

	const [updateQuoteConfigurationShowTotalBoxes] =
		updateQuoteConfigurationShowTotalBoxesMutation();

	const [updateQuoteConfigurationLayout] = updateQuoteConfigurationLayoutMutation();

	const [updateQuoteConfigurationShowFirstPaymentBox] =
		updateQuoteConfigurationShowFirstPaymentBoxMutation();

	return (
		<S.Wrapper>
			<StyledCheckboxGroup
				checkIcon
				focusColor="#FFCD38"
				boxLabelDistance="0px"
				interBoxDistance="8px"
				style={{ marginLeft: "-7.5px" }}
				selected={showTotalBoxes ? ["showTotalBoxes"] : []}
				onChange={() => {
					updateQuoteConfigurationShowTotalBoxes({
						variables: {
							updateQuoteConfiguration: {
								id: quoteConfigurationId,
								showTotalBoxes: !showTotalBoxes,
							},
						},
						optimisticResponse: {
							updateQuoteConfiguration: {
								__typename: "QuoteConfiguration",
								id: quoteConfigurationId,
								showTotalBoxes: !showTotalBoxes,
							},
						},
						update(cache) {
							priceModelCombinationId !== null &&
								updateUpdateAtInCacheForLinks(cache, priceModelCombinationId);
						},
					});
				}}
			>
				<Checkbox.Button mode={mode} value={"showTotalBoxes"} width="15px" height="15px">
					<StyledTextCheckbox>{t("Totals insert on front page")}</StyledTextCheckbox>
				</Checkbox.Button>
			</StyledCheckboxGroup>
			{showTotalBoxes && (
				<>
					<RowWrapper>
						<S.WrapperSelectAndTitle>
							<S.SelectTitle>{t("Front page layout")}</S.SelectTitle>
							<WrapperSelectAndIcon>
								<SingleSelect.Group
									type="basic"
									mode={mode}
									width={isOfferParameters ? "266px" : "100%"}
									widthOption="100%"
									widthOptionColoredBox="100%"
									onChange={layoutValue => {
										updateQuoteConfigurationLayout({
											variables: {
												updateQuoteConfiguration: {
													id: quoteConfigurationId,
													layout: layoutValue,
												},
											},
											optimisticResponse: {
												updateQuoteConfiguration: {
													__typename: "QuoteConfiguration",
													id: quoteConfigurationId,
													layout: layoutValue,
												},
											},
											update(cache) {
												priceModelCombinationId !== null &&
													updateUpdateAtInCacheForLinks(
														cache,
														priceModelCombinationId
													);
											},
										});
									}}
									value={layout}
									appendTo={document.body}
									data={[
										{
											value: "detailed",
											option: (
												<SingleSelect.Option center>
													{t("One total box per time metric")}
												</SingleSelect.Option>
											),
										},
										{
											value: "compact",
											option: (
												<SingleSelect.Option center>
													{t(
														"A single total box detailing the recurring total amount"
													)}
												</SingleSelect.Option>
											),
										},
										{
											value: "packsAndSections",
											option: (
												<SingleSelect.Option center>
													<PacksAndSectionsOptionContent>
														<Tooltipv3
															placement={"right"}
															mode={mode}
															type={"warning"}
															appendTo={document.body}
															content={
																<TooltipContent>
																	{t(
																		"This option should not be used in conjonction with variable time discounts and global discounts because the PDF quote will be wrong in such case."
																	)}
																</TooltipContent>
															}
														>
															{t(
																"Total box detailed by pack and sections"
															)}
														</Tooltipv3>
													</PacksAndSectionsOptionContent>
												</SingleSelect.Option>
											),
										},
									]}
								/>
								{layout === "packsAndSections" && (
									<Tooltipv3
										placement={"right"}
										mode={mode}
										type={"warning"}
										appendTo={document.body}
										content={
											<TooltipContent>
												{t(
													"This option should not be used in conjonction with variable time discounts and global discounts because the PDF quote will be wrong in such case."
												)}
											</TooltipContent>
										}
									>
										<WrapperWarningIcon>
											<WarningIcon
												type={"warning2"}
												fill={feedbackColors.warningColor}
											/>
										</WrapperWarningIcon>
									</Tooltipv3>
								)}
							</WrapperSelectAndIcon>
						</S.WrapperSelectAndTitle>
					</RowWrapper>
					<StyledCheckboxGroup
						checkIcon
						focusColor="#FFCD38"
						boxLabelDistance="0px"
						interBoxDistance="8px"
						style={{ marginLeft: "-7.5px" }}
						selected={showFirstPaymentBox ? ["showFirstPaymentBox"] : []}
						onChange={() => {
							updateQuoteConfigurationShowFirstPaymentBox({
								variables: {
									updateQuoteConfiguration: {
										id: quoteConfigurationId,
										showFirstPaymentBox: !showFirstPaymentBox,
									},
								},
								optimisticResponse: {
									updateQuoteConfiguration: {
										__typename: "QuoteConfiguration",
										id: quoteConfigurationId,
										showFirstPaymentBox: !showFirstPaymentBox,
									},
								},
								update(cache) {
									priceModelCombinationId !== null &&
										updateUpdateAtInCacheForLinks(
											cache,
											priceModelCombinationId
										);
								},
							});
						}}
					>
						<Tooltipv3
							key={"firstPaymentBox"}
							placement="top"
							mode={mode}
							type="primary"
							maxWidth="350px"
							content={t(
								"A box showing the summary of the first payment will be displayed on the first page. This first payment may correspond to the total invoice amount in the case of a non-recurring payment."
							)}
							interactive={false}
							appendTo={document.body}
						>
							<Checkbox.Button
								mode={mode}
								value={"showFirstPaymentBox"}
								width="15px"
								height="15px"
							>
								<StyledTextCheckbox>{t("First payment amount")}</StyledTextCheckbox>
							</Checkbox.Button>
						</Tooltipv3>
					</StyledCheckboxGroup>
				</>
			)}
		</S.Wrapper>
	);
});

export default TotalBoxes;
