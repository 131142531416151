import { gql, useMutation } from "@apollo/client";
import {
	OFFER_PROPERTIES_ATTACHED_LINK_FRAGMENT,
	OFFER_PROPERTIES_ATTACHED_LINK_TITLE_FRAGMENT,
	OFFER_PROPERTIES_ATTACHED_PRESENTATION_FRAGMENT,
	OFFER_PROPERTIES_ATTACHED_PRESENTATION_TITLE_FRAGMENT,
	OFFER_PROPERTIES_CLIENTS_LOGO_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT,
	OFFER_PROPERTIES_ENABLE_TC_FRAGMENT,
	OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT,
	OFFER_PROPERTIES_FOR_OFFER_FRAGMENT,
	OFFER_PROPERTIES_ID_FRAGMENT,
	OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT,
	OFFER_PROPERTIES_NAME_FRAGMENT,
	OFFER_PROPERTIES_FOOTER_TITLE_FRAGMENT,
} from "../fragments/offerProperties";

export const UPDATE_ENABLE_TC_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ENABLE_TC_FRAGMENT}
	mutation UpdateEnableTcOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesEnableTcFragment
		}
	}
`;

export const updateOfferPropertiesEnableTcMutation = () => {
	const [updateEnableTcOfferProperties, { loading, error }] = useMutation(
		UPDATE_ENABLE_TC_OFFER_PROPERTIES
	);
	return [updateEnableTcOfferProperties, { loading, error }];
};

export const UPDATE_FOOTER_CONTENT_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_FOOTER_CONTENT_FRAGMENT}
	mutation UpdateFooterContentOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesFooterContentFragment
		}
	}
`;

export const UPDATE_FOOTER_TITLE_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_FOOTER_TITLE_FRAGMENT}
	mutation UpdateFooterTitleOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesFooterTitleFragment
		}
	}
`;

export const updateOfferPropertiesFooterContentMutation = () => {
	const [updateFooterContentOfferProperties, { loading, error }] = useMutation(
		UPDATE_FOOTER_CONTENT_OFFER_PROPERTIES
	);
	return [updateFooterContentOfferProperties, { loading, error }];
};

export const updateOfferPropertiesFooterTitleMutation = () => {
	const [updateFooterTitleOfferProperties, { loading, error }] = useMutation(
		UPDATE_FOOTER_TITLE_OFFER_PROPERTIES
	);
	return [updateFooterTitleOfferProperties, { loading, error }];
};

export const UPDATE_ENABLE_FOOTER_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ENABLE_FOOTER_FRAGMENT}
	mutation UpdateEnableFooterOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesEnableFooterFragment
		}
	}
`;

export const updateOfferPropertiesEnableFooterMutation = () => {
	const [updateEnableFooterOfferProperties, { loading, error }] = useMutation(
		UPDATE_ENABLE_FOOTER_OFFER_PROPERTIES
	);
	return [updateEnableFooterOfferProperties, { loading, error }];
};

export const UPDATE_ENABLE_DEAL_OWNER_CONTACT_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ENABLE_DEAL_OWNER_CONTACT_FRAGMENT}
	mutation UpdateEnableDealOwnerContactOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesEnableDealOwnerContactFragment
		}
	}
`;

export const updateEnableDealOwnerContactOfferPropertiesMutation = () => {
	const [updateEnableDealOwnerContactOfferProperties, { loading, error }] = useMutation(
		UPDATE_ENABLE_DEAL_OWNER_CONTACT_OFFER_PROPERTIES
	);
	return [updateEnableDealOwnerContactOfferProperties, { loading, error }];
};

export const UPDATE_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	mutation UpdateEnableIntroductionTextForProspectOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesEnableIntroductionTextForProspectFragment
		}
	}
`;

export const updateEnableIntroductionTextForProspectOfferPropertiesMutation = () => {
	const [updateEnableIntroductionTextForProspectOfferProperties, { loading, error }] =
		useMutation(UPDATE_ENABLE_INTRODUCTION_TEXT_FOR_PROSPECT_OFFER_PROPERTIES);
	return [updateEnableIntroductionTextForProspectOfferProperties, { loading, error }];
};

export const UPDATE_INTRODUCTION_TEXT_FOR_PROSPECT_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_INTRODUCTION_TEXT_FOR_PROSPECT_FRAGMENT}
	mutation UpdateIntroductionTextForProspectOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesIntroductionTextForProspectFragment
		}
	}
`;

export const updateIntroductionTextForProspectOfferPropertiesMutation = () => {
	const [updateIntroductionTextForProspectOfferProperties, { loading, error }] = useMutation(
		UPDATE_INTRODUCTION_TEXT_FOR_PROSPECT_OFFER_PROPERTIES
	);
	return [updateIntroductionTextForProspectOfferProperties, { loading, error }];
};

export const UPDATE_ENABLE_ATTACHED_PRESENTATION_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ENABLE_ATTACHED_PRESENTATION_FRAGMENT}
	mutation UpdateEnableAttachedPresentationOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesEnableAttachedPresentationFragment
		}
	}
`;

export const updateEnableAttachedPresentationOfferPropertiesMutation = () => {
	const [updateEnableAttachedPresentationOfferProperties, { loading, error }] = useMutation(
		UPDATE_ENABLE_ATTACHED_PRESENTATION_OFFER_PROPERTIES
	);
	return [updateEnableAttachedPresentationOfferProperties, { loading, error }];
};

export const UPDATE_ATTACHED_PRESENTATION_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_FRAGMENT}
	mutation UpdateAttachedPresentationOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesAttachedPresentationFragment
		}
	}
`;

export const updateAttachedPresentationOfferPropertiesMutation = () => {
	const [updateAttachedPresentationOfferProperties, { loading, error }] = useMutation(
		UPDATE_ATTACHED_PRESENTATION_OFFER_PROPERTIES
	);
	return [updateAttachedPresentationOfferProperties, { loading, error }];
};

export const UPDATE_ATTACHED_PRESENTATION_TITLE_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ATTACHED_PRESENTATION_TITLE_FRAGMENT}
	mutation UpdateAttachedPresentationTitleOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesAttachedPresentationTitleFragment
		}
	}
`;

export const updateAttachedPresentationTitleOfferPropertiesMutation = () => {
	const [updateAttachedPresentationTitleOfferProperties, { loading, error }] = useMutation(
		UPDATE_ATTACHED_PRESENTATION_TITLE_OFFER_PROPERTIES
	);
	return [updateAttachedPresentationTitleOfferProperties, { loading, error }];
};

export const UPDATE_ENABLE_ATTACHED_LINK_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ENABLE_ATTACHED_LINK_FRAGMENT}
	mutation UpdateEnableAttachedLinkOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesEnableAttachedLinkFragment
		}
	}
`;

export const updateEnableAttachedLinkOfferPropertiesMutation = () => {
	const [updateEnableAttachedLinkOfferProperties, { loading, error }] = useMutation(
		UPDATE_ENABLE_ATTACHED_LINK_OFFER_PROPERTIES
	);
	return [updateEnableAttachedLinkOfferProperties, { loading, error }];
};
export const UPDATE_ENABLE_CLIENTS_LOGO_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ENABLE_CLIENTS_LOGO_FRAGMENT}
	mutation UpdateEnableClientsLogoOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesEnableClientsLogoFragment
		}
	}
`;

export const updateEnableClientsLogoOfferPropertiesMutation = () => {
	const [updateEnableClientsLogoOfferProperties, { loading, error }] = useMutation(
		UPDATE_ENABLE_CLIENTS_LOGO_OFFER_PROPERTIES
	);
	return [updateEnableClientsLogoOfferProperties, { loading, error }];
};
export const UPDATE_CLIENTS_LOGO_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_CLIENTS_LOGO_FRAGMENT}
	mutation UpdateClientsLogoOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesClientsLogoFragment
		}
	}
`;

export const updateClientsLogoOfferPropertiesMutation = () => {
	const [updateClientsLogoOfferProperties, { loading, error }] = useMutation(
		UPDATE_CLIENTS_LOGO_OFFER_PROPERTIES
	);
	return [updateClientsLogoOfferProperties, { loading, error }];
};

export const UPDATE_ATTACHED_LINK_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ATTACHED_LINK_FRAGMENT}
	mutation UpdateAttachedLinkOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesAttachedLinkFragment
		}
	}
`;

export const updateAttachedLinkOfferPropertiesMutation = () => {
	const [updateAttachedLinkOfferProperties, { loading, error }] = useMutation(
		UPDATE_ATTACHED_LINK_OFFER_PROPERTIES
	);
	return [updateAttachedLinkOfferProperties, { loading, error }];
};

export const UPDATE_ATTACHED_TITLE_LINK_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_ATTACHED_LINK_TITLE_FRAGMENT}
	mutation UpdateAttachedTitleLinkOfferProperties($offerProperties: UpdateOfferPropertiesInput!) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesAttachedLinkTitleFragment
		}
	}
`;

export const updateAttachedLinkTitleOfferPropertiesMutation = () => {
	const [updateAttachedLinkTitleOfferProperties, { loading, error }] = useMutation(
		UPDATE_ATTACHED_TITLE_LINK_OFFER_PROPERTIES
	);
	return [updateAttachedLinkTitleOfferProperties, { loading, error }];
};

export const UPDATE_NAME_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_NAME_FRAGMENT}
	mutation UpdateEnableAttachedPresentationOfferProperties(
		$offerProperties: UpdateOfferPropertiesInput!
	) {
		updateOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesNameFragment
		}
	}
`;

export const updateNameOfferPropertiesMutation = () => {
	const [updateNameOfferProperties, { loading, error }] = useMutation(
		UPDATE_NAME_OFFER_PROPERTIES
	);
	return [updateNameOfferProperties, { loading, error }];
};

const CREATE_OFFER_PROPERTIES = gql`
	${OFFER_PROPERTIES_FOR_OFFER_FRAGMENT}
	mutation CreateOfferProperties($offerProperties: CreateOfferPropertiesInput!) {
		createOfferProperties(offerProperties: $offerProperties) {
			...OfferPropertiesForOfferFragment
		}
	}
`;

export const createOfferPropertiesMutation = () => {
	const [createOfferProperties, { loading }] = useMutation(CREATE_OFFER_PROPERTIES, {
		update(cache, { data: { createOfferProperties } }) {
			cache.modify({
				id: `Offer:${createOfferProperties.offerId}`,
				fields: {
					offerProperties() {
						const offerPropertiesRef = cache.writeFragment({
							data: createOfferProperties,
							fragment: OFFER_PROPERTIES_ID_FRAGMENT,
							fragmentName: "OfferPropertiesIdFragment",
						});
						return offerPropertiesRef;
					},
				},
			});
		},
	});
	return [createOfferProperties, { loading }];
};
